<script setup lang="ts">
defineProps<{ modelValue?: number }>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: number | null): void;
}>();
const options = new Array(100).fill(dayjs().year()).map((val, i) => val - i);
</script>

<template>
  <VueSelect
    :modelValue="modelValue"
    :options="options"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>
