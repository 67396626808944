<script setup lang="ts">
const props = defineProps<{
  modelValue?: number;
  /**
   * Used to generate the correct number of days
   */
  month?: number;
  /**
   * Used to generate the correct number of days
   */
  year?: number;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', v: number | null): void;
}>();
const options = computed(() => {
  let days = 31;

  if (props.month && props.year) {
    days = dayjs().month(props.month).year(props.year).daysInMonth();
  }

  return new Array(days).fill(1).map((val, i) => val + i);
});

watch(options, (options) => {
  if (!options.includes(props.modelValue)) {
    emit('update:modelValue', null);
  }
});
</script>

<template>
  <VueSelect
    :modelValue="modelValue"
    :options="options"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>
